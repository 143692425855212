import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';

@Injectable()
export class UserProvider {
  constructor(public http: HttpClient, private appService: AppService) { }

  public login({ email, password }: any): Observable<any> {
    return this.http.post(environment.ENDPOINT + environment.SERVICES.LOGIN, {email, password});
  }

  public recovery({ email, newPassword, oldPassword }: any): Observable<any> {
    return this.http.post(environment.ENDPOINT + environment.SERVICES.RECOVERY, { email, newPassword, oldPassword });
  }

  public resetPassword({ email, newPassword, code }: any): Observable<any> {
    return this.http.post(environment.ENDPOINT + environment.SERVICES.RESET_PASSWORD, { email, newPassword, code });
  }

  public requestResetPassword(email: string): Observable<any> {
    return this.http.post(environment.ENDPOINT + environment.SERVICES.REQUEST_RESET_PASSWORD, { email });
  }
  
  public menu(): Observable<any> {
    return this.http.get(environment.ENDPOINT + environment.SERVICES.MENU);
  }

  public create(form: any): Observable<any> {
    const suscription = this.appService.getViewSuscription();
    return this.http.post(environment.ENDPOINT + environment.SERVICES.USER, {
      ...form,
      suscription: suscription || form.suscription
    });
  }

  public getList(): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.USER_LIST}?suscription=${this.appService.getViewSuscription()}`);
  }

  public getListAdmin(): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.USER_LIST_ADMIN}?suscription=${this.appService.getViewSuscription()}`);
  }

  public getListGroup(): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.USER_LIST_GROUP}?suscription=${this.appService.getViewSuscription()}`);
  }

  public get(): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.USER}?suscription=${this.appService.getViewSuscription()}`);
  }

  public update(form: any): Observable<any> {
    const suscription = this.appService.getViewSuscription();
    return this.http.put(`${environment.ENDPOINT}${environment.SERVICES.USER}/${form.email}`, {
      ...form,
      suscription: suscription || form.suscription
    });
  }

  public deleteUser(email: string): Observable<any> {
    return this.http.delete(environment.ENDPOINT + `${environment.SERVICES.USER_DELETE}/${email}?suscription=${this.appService.getViewSuscription()}`);
  }
}
